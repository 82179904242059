import { getDefaultOptions } from './auth';

export const track = async (payload:object, token?:string) => {
  const config = useRuntimeConfig();
  const options = await getDefaultOptions();
  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  const res = await $fetch(
    config.public.apiBaseUrl + '/affiliates/track',
    { ...options, body: payload, method: 'POST' },
  );
  return res;
};
