import get from 'lodash/get';

import { getToken, setToken, start, track } from '@/services';

const UTM = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'source_id',
];

export default defineNuxtRouteMiddleware(async (to) => {
  const params = Object.keys(to.query);
  if (UTM.some(param => params.includes(param))) {
    let token = getToken();
    if (!token) {
      token = await start();
    }
    await setToken(token!);

    const utmSource = get(to, 'query.utm_source');
    const utmMedium = get(to, 'query.utm_medium');
    const utmCampaign = get(to, 'query.utm_campaign');
    const utmTerm = get(to, 'query.utm_term');
    const utmContent = get(to, 'query.utm_content');
    const headers = useRequestHeaders();
    const referrer = get(headers, 'referrer', get(headers, 'referer'));
    const sourceId = get(to, 'query.source_id');

    const payload = {
      utmSource,
      utmMedium,
      utmCampaign,
      utmTerm,
      utmContent,
      referrer,
      sourceId,
    };

    await track(payload, token);
  }
});
